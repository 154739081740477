import get from 'lodash/get';
import { config } from './app/config';

const calculateCountdown = (auction) => {
  if (auction) {
    const { appliedSetting } = auction;
    if (appliedSetting) {
      const now = new Date();
      const tz = now.getTimezoneOffset() * 60 * 1000;

      const splitted = get(appliedSetting, 'priceTime.appliesTo', '').split(/[^0-9]/);
      const end = new Date(
        splitted[0],
        splitted[1] - 1,
        splitted[2],
        splitted[3],
        splitted[4],
        splitted[5],
      );

      const totalTimeLeft = end - now - tz;

      const hoursLeft = Math.floor(totalTimeLeft / (1000 * 60 * 60));
      const minutesLeft = Math.floor((totalTimeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const secondsLeft = Math.floor((totalTimeLeft % (1000 * 60)) / 1000);

      const currentAuctionDuration = appliedSetting.duration;

      return {
        time: {
          hours: hoursLeft > 0 ? hoursLeft : 0,
          minutes: minutesLeft > 0 ? minutesLeft : 0,
          seconds: secondsLeft > 0 ? secondsLeft : 0,
        },
        countdownProgress: (totalTimeLeft * 100) / (currentAuctionDuration * 1000),
      };
    }
  }
  return null;
};

const extractLocaleFromPathname = () => {
  const { pathname } = window.location;
  const regex = /^\/([a-zA-Z]{2})(\/|$)/;
  const match = pathname.match(regex);

  return match && match[1];
};

const getCurrentLanguage = () => extractLocaleFromPathname() || config.defaultMarket;

const getAvailableMarkets = () => Object.keys(config.markets);

export { getAvailableMarkets, getCurrentLanguage, calculateCountdown, extractLocaleFromPathname };
