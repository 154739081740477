<template>
  <nav class="navbar">
    <div class="d-flex align-items-center flex-grow-1">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'home' }" tag="a" class="navbar-brand">
          <img src="/logo.svg" height="70" alt="Juodelių logotipas" />
        </router-link>
        <div class="d-lg-block d-none ml-3">
          <h1>{{ $t('common.title') }}</h1>
        </div>
      </div>
      <div class="d-sm-flex d-none align-items-center flex-grow-1 justify-content-evenly">
        <img src="/images/jtimber.svg" height="70" alt="JTimber logotipas" class="mx-3" />
      </div>
    </div>
    <div class="d-md-flex flex-row d-none">
      <button
        type="button"
        class="btn btn-nav-link"
        :class="{ 'active-btn': activeSidebar === 'contacts' }"
        @click.stop="$emit('showContacts')"
      >
        {{ $t('common.contactsLabel') }}
      </button>
      <div v-if="userLoadStatus === 1" class="user-icon user-icon--skeleton" />
      <router-link
        v-else-if="!user"
        :to="{ name: 'login' }"
        tag="button"
        class="btn btn-primary d-flex align-items-center justify-content-center"
      >
        <span>{{ $t('common.loginLabel') }}</span>
        <img src="/images/svg/login.svg" aria-hidden="true" class="ml-2" width="16" alt="" />
      </router-link>

      <div v-if="!!user" class="user-icon">
        <button type="button" class="btn btn-nav-link" @click.stop="makeActiveMenu('user')">
          <i class="far fa-user-circle cursor-pointer" />
        </button>
        <div v-if="showUserMenu" v-on-clickaway="close" class="user-menu">
          <button v-if="isAdmin" type="button" class="btn btn-dropdown" @click="redirectToBO">
            <i class="fas fa-user-lock" />
            <span>Backoffice</span>
          </button>
          <router-link
            :to="{ name: 'profile' }"
            tag="button"
            class="btn btn-dropdown"
            active-class="text-green"
          >
            <i class="fas fa-user" />
            <span>{{ $t('common.profileLabel') }}</span>
          </router-link>
          <router-link
            :to="{ name: 'reservations' }"
            tag="button"
            class="btn btn-dropdown"
            active-class="text-green"
          >
            <i class="fas fa-list-ol" />
            <span>{{ $t('common.reservationHistoryLabel') }}</span>
          </router-link>
          <button type="button" class="btn btn-dropdown" @click="logout">
            <i class="fas fa-sign-out-alt" />
            {{ $t('common.logoutLabel') }}
          </button>
        </div>
      </div>
    </div>
    <div class="d-md-none ml-auto flex-row d-flex">
      <i class="fas fa-bars cursor-pointer" @click.stop="showMobileMenu = !showMobileMenu" />
    </div>
    <aside
      v-on-clickaway="hideMobileMenu"
      class="drawer-nav drawer-nav--left"
      :class="{ 'is-open': showMobileMenu }"
    >
      <div class="text-center">
        <router-link :to="{ name: 'home' }" tag="a" class="navbar-brand">
          <img src="/logo.svg" width="85" alt="Juodelių logotipas" />
        </router-link>
      </div>
      <div class="text-center">
        <h5>{{ $t('common.title') }}</h5>
      </div>
      <div class="drawer-nav__wrapper">
        <div class="w-100">
          <hr />
          <div class="drawer-nav__nav-items">
            <button type="button" class="btn btn-nav-link" @click.stop="$emit('showContacts')">
              {{ $t('common.contactsLabel') }}
            </button>
            <hr />
            <div v-if="!!user">
              <button v-if="isAdmin" type="button" class="btn btn-nav-link" @click="redirectToBO">
                <i class="fas fa-user-lock" />
                <span>Backoffice</span>
              </button>
              <router-link
                :to="{ name: 'profile' }"
                tag="button"
                class="btn btn-nav-link"
                active-class="text-green"
              >
                <i class="fas fa-user" />
                <span>{{ $t('common.profileLabel') }}</span>
              </router-link>
              <router-link
                :to="{ name: 'reservations' }"
                tag="button"
                class="btn btn-nav-link"
                active-class="text-green"
              >
                <i class="fas fa-list-ol" />
                <span>{{ $t('common.reservationHistoryLabel') }}</span>
              </router-link>
              <button type="button" class="btn btn-nav-link" @click="logout">
                <i class="fas fa-sign-out-alt" />
                {{ $t('common.logoutLabel') }}
              </button>
            </div>
            <router-link v-else :to="{ name: 'login' }" tag="button" class="btn btn-nav-link">
              <i class="fas fa-sign-in-alt mr-2" />
              <span>{{ $t('common.loginLabel') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </aside>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCurrentLanguage } from '../../../utils';

export default {
  name: 'Navbar',
  props: {
    activeSidebar: {
      type: String,
      required: false,
      default: null,
    },
    closeNavbarMenus: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showUserMenu: false,
      showMobileMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
      isAdmin: 'isAdmin',
      userLoadStatus: 'userLoadStatus',
    }),
  },
  watch: {
    closeNavbarMenus(val) {
      if (val) {
        this.showUserMenu = false;
        this.showMobileMenu = false;
      }
    },
    showUserMenu(val) {
      this.$emit('toggleNavbarMenu', !val);
    },
    showMobileMenu(val) {
      this.$emit('toggleNavbarMenu', !val);
    },
  },
  methods: {
    logout() {
      axios.post('/logout').then(() => {
        this.$store.dispatch('setUser', null);
        this.$store.dispatch('setIsAdmin', false);
        localStorage.removeItem('wua');
        window.location.href = `/${getCurrentLanguage()}`;
      });
    },
    redirectToBO() {
      window.location.href = `/${getCurrentLanguage()}/admin`;
    },
    makeActiveMenu(type) {
      if (type === 'user') {
        this.showUserMenu = !this.showUserMenu;
      }
    },
    close() {
      this.showUserMenu = false;
    },
    hideMobileMenu() {
      this.showMobileMenu = false;
    },
  },
};
</script>
