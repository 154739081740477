var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.close,
          expression: "close"
        }
      ],
      staticClass: "drawer-nav",
      class: [{ "is-open": _vm.isOpen }, _vm.customClass],
      attrs: { id: _vm.customId }
    },
    [
      _c("div", { staticClass: "drawer-nav__close text-right" }, [
        _c("i", { staticClass: "fas fa-times", on: { click: _vm.close } })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-nav__wrapper" }, [
        _c("div", { staticClass: "w-100 flex-1" }, [_vm._t("default")], 2)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }