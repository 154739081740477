var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar" }, [
    _c("div", { staticClass: "d-flex align-items-center flex-grow-1" }, [
      _c(
        "div",
        { staticClass: "d-flex align-items-center" },
        [
          _c(
            "router-link",
            {
              staticClass: "navbar-brand",
              attrs: { to: { name: "home" }, tag: "a" }
            },
            [
              _c("img", {
                attrs: {
                  src: "/logo.svg",
                  height: "70",
                  alt: "Juodelių logotipas"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-lg-block d-none ml-3" }, [
            _c("h1", [_vm._v(_vm._s(_vm.$t("common.title")))])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0)
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-md-flex flex-row d-none" },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-nav-link",
            class: { "active-btn": _vm.activeSidebar === "contacts" },
            attrs: { type: "button" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$emit("showContacts")
              }
            }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("common.contactsLabel")) + "\n    "
            )
          ]
        ),
        _vm._v(" "),
        _vm.userLoadStatus === 1
          ? _c("div", { staticClass: "user-icon user-icon--skeleton" })
          : !_vm.user
          ? _c(
              "router-link",
              {
                staticClass:
                  "btn btn-primary d-flex align-items-center justify-content-center",
                attrs: { to: { name: "login" }, tag: "button" }
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.$t("common.loginLabel")))]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "ml-2",
                  attrs: {
                    src: "/images/svg/login.svg",
                    "aria-hidden": "true",
                    width: "16",
                    alt: ""
                  }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !!_vm.user
          ? _c("div", { staticClass: "user-icon" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-nav-link",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.makeActiveMenu("user")
                    }
                  }
                },
                [_c("i", { staticClass: "far fa-user-circle cursor-pointer" })]
              ),
              _vm._v(" "),
              _vm.showUserMenu
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "on-clickaway",
                          rawName: "v-on-clickaway",
                          value: _vm.close,
                          expression: "close"
                        }
                      ],
                      staticClass: "user-menu"
                    },
                    [
                      _vm.isAdmin
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-dropdown",
                              attrs: { type: "button" },
                              on: { click: _vm.redirectToBO }
                            },
                            [
                              _c("i", { staticClass: "fas fa-user-lock" }),
                              _vm._v(" "),
                              _c("span", [_vm._v("Backoffice")])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-dropdown",
                          attrs: {
                            to: { name: "profile" },
                            tag: "button",
                            "active-class": "text-green"
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-user" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("common.profileLabel")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-dropdown",
                          attrs: {
                            to: { name: "reservations" },
                            tag: "button",
                            "active-class": "text-green"
                          }
                        },
                        [
                          _c("i", { staticClass: "fas fa-list-ol" }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("common.reservationHistoryLabel"))
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-dropdown",
                          attrs: { type: "button" },
                          on: { click: _vm.logout }
                        },
                        [
                          _c("i", { staticClass: "fas fa-sign-out-alt" }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("common.logoutLabel")) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-md-none ml-auto flex-row d-flex" }, [
      _c("i", {
        staticClass: "fas fa-bars cursor-pointer",
        on: {
          click: function($event) {
            $event.stopPropagation()
            _vm.showMobileMenu = !_vm.showMobileMenu
          }
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "aside",
      {
        directives: [
          {
            name: "on-clickaway",
            rawName: "v-on-clickaway",
            value: _vm.hideMobileMenu,
            expression: "hideMobileMenu"
          }
        ],
        staticClass: "drawer-nav drawer-nav--left",
        class: { "is-open": _vm.showMobileMenu }
      },
      [
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "router-link",
              {
                staticClass: "navbar-brand",
                attrs: { to: { name: "home" }, tag: "a" }
              },
              [
                _c("img", {
                  attrs: {
                    src: "/logo.svg",
                    width: "85",
                    alt: "Juodelių logotipas"
                  }
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _c("h5", [_vm._v(_vm._s(_vm.$t("common.title")))])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "drawer-nav__wrapper" }, [
          _c("div", { staticClass: "w-100" }, [
            _c("hr"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "drawer-nav__nav-items" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-nav-link",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.$emit("showContacts")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.contactsLabel")) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                !!_vm.user
                  ? _c(
                      "div",
                      [
                        _vm.isAdmin
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-nav-link",
                                attrs: { type: "button" },
                                on: { click: _vm.redirectToBO }
                              },
                              [
                                _c("i", { staticClass: "fas fa-user-lock" }),
                                _vm._v(" "),
                                _c("span", [_vm._v("Backoffice")])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-nav-link",
                            attrs: {
                              to: { name: "profile" },
                              tag: "button",
                              "active-class": "text-green"
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-user" }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.profileLabel")))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-nav-link",
                            attrs: {
                              to: { name: "reservations" },
                              tag: "button",
                              "active-class": "text-green"
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-list-ol" }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("common.reservationHistoryLabel"))
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-nav-link",
                            attrs: { type: "button" },
                            on: { click: _vm.logout }
                          },
                          [
                            _c("i", { staticClass: "fas fa-sign-out-alt" }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("common.logoutLabel")) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c(
                      "router-link",
                      {
                        staticClass: "btn btn-nav-link",
                        attrs: { to: { name: "login" }, tag: "button" }
                      },
                      [
                        _c("i", { staticClass: "fas fa-sign-in-alt mr-2" }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("common.loginLabel")))
                        ])
                      ]
                    )
              ],
              1
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-sm-flex d-none align-items-center flex-grow-1 justify-content-evenly"
      },
      [
        _c("img", {
          staticClass: "mx-3",
          attrs: {
            src: "/images/jtimber.svg",
            height: "70",
            alt: "JTimber logotipas"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }