var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "district-p-l" },
    [
      _c("div", { staticClass: "drawer-nav__header" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("img", {
            attrs: {
              src: "/images/svg/mail.svg",
              alt: "",
              "aria-hidden": "true"
            }
          }),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.$t("common.contactsLabel")))])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-nav__type" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("contactsPage.factories")))])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer-nav__text" },
        _vm._l(_vm.factories, function(factory) {
          return _c("div", { key: factory.title, staticClass: "mb-3" }, [
            _c("strong", [_vm._v(_vm._s(factory.title))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(factory.address))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(factory.location))]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("contactsPage.coordinates")) +
                  ":\n        "
              ),
              _c("i", { staticClass: "fas fa-map-marker-alt mr-1 text-green" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "text-green",
                  attrs: {
                    href: factory.coordinates.url,
                    rel: "noopener noreferrer",
                    target: "_blank"
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(factory.coordinates.longLat) +
                      "\n        "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            factory.woodAcceptanceTime
              ? _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("contactsPage.woodAcceptanceTime")) +
                      ": I - V " +
                      _vm._s(factory.woodAcceptanceTime) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-nav__type" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("contactsPage.contactPersons")))])
      ]),
      _vm._v(" "),
      _c("contact-persons"),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-nav__type" }, [
        _c("h4", [_vm._v(_vm._s(_vm.$t("contactsPage.props")))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-nav__text" }, [
        _c("div", { staticClass: "mb-3" }, [
          _c("p", [
            _c("strong", [_vm._v(_vm._s(_vm.$t("contactsPage.disclaimer")))])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _c("strong", [_vm._v(_vm._s(_vm.$t("contactsPage.address")) + ":")]),
          _vm._v(" "),
          _c("p", [_vm._v("J. Basanavičiaus a. 9,")]),
          _vm._v(" "),
          _c("p", [_vm._v("LT-68308 Marijampolė,")]),
          _vm._v(" "),
          _c("p", [_vm._v("Lietuva")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("contactsPage.emailShort")) +
                ":\n        "
            ),
            _vm._m(0)
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("contactsPage.website")) +
                ":\n        "
            ),
            _vm._m(1)
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "mailto:info@miskovezis.lt" } }, [
      _c("span", [_vm._v("info@miskovezis.lt")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "https://www.miskovezis.lt" } }, [
      _c("span", [_vm._v("www.miskovezis.lt")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }