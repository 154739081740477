<template>
  <footer class="bg-white">
    <div class="container">
      <div class="position-relative py-3">
        <div class="d-flex justify-content-between align-items-center">
          <div>© {{ thisYear }} {{ $t('footer.copyright') }}</div>
          <div class="position-relative">
            <router-link
              :to="{ name: 'privacy-policy' }"
              tag="a"
              class="pl-sm-2 d-sm-inline d-block"
            >
              {{ $t('privacyPolicyPage.title') }}
            </router-link>

            <router-link
              :to="{ name: 'cookie-policy' }"
              tag="a"
              class="pl-sm-2 d-sm-inline d-block"
            >
              {{ $t('cookiePolicyPage.title') }}
            </router-link>
          </div>
        </div>

        <div class="floating-btn__wrapper">
          <button type="button" class="btn btn-primary floating-btn" @click="openFreshChat">
            <span class="d-inline-flex align-items-center">
              {{ $t('footer.consultationCTA') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  computed: {
    thisYear() {
      const d = new Date();
      return d.getFullYear();
    },
  },
  methods: {
    openFreshChat() {
      window.fcWidget.open();
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  font-size: 12px;
  margin-top: 50px;
}

.floating-btn__wrapper {
  position: absolute;
  top: -16px;
  transform: translateY(-100%);
  right: 0;
}

.floating-btn {
  padding: 6px 46px 6px 20px;

  &::after {
    content: '';
    background-image: url('/images/svg/chat_icon.svg');
    height: 16px;
    width: 16px;
    display: inline-flex;
    background-repeat: no-repeat;
    line-height: 1;
    margin-left: auto;
    position: absolute;
    right: 20px;
  }
}
</style>
