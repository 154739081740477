const config = {
  markets: {
    lt: {
      contactPerson: {
        name: 'Aira Maksimavičienė',
        phone: '+370 687 99677',
        email: 'a.maksimaviciene@jtimber.lt',
      },
    },
    lv: {
      contactPerson: {
        name: 'Valters Apfelbaums',
        phone: '+371 295 29115',
        email: 'v.apfelbaums@jtimber.lv',
        imageUrl: '/images/worker/valters.jpg',
      },
    },
  },
  defaultMarket: 'lt',
};

export { config };
