var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drawer-nav__contact" },
    _vm._l(_vm.workers[_vm.getCurrentLanguage()], function(worker) {
      return _c("div", { key: worker.name, staticClass: "d-flex mb-2" }, [
        _c("div", { staticClass: "avatar" }, [
          worker.imageUrl
            ? _c("img", {
                attrs: {
                  src: worker.imageUrl,
                  alt: worker.name + " portretinė nuotrauka"
                }
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-column ml-3" }, [
          _c("div", { staticClass: "worker" }, [_vm._v(_vm._s(worker.name))]),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              { attrs: { href: "tel:" + worker.phone.replace(" ", "") } },
              [_vm._v("\n          " + _vm._s(worker.phone) + "\n        ")]
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c("a", { attrs: { href: "mailto:" + worker.email } }, [
              _vm._v("\n          " + _vm._s(worker.email) + "\n        ")
            ])
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }