var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column mh-100 lit-map map-bg" },
    [
      _c("div", { staticClass: "bg-white" }, [
        _c(
          "div",
          { staticClass: "container container-nav" },
          [
            _c("navbar", {
              attrs: {
                "active-sidebar": _vm.getActiveSidebar,
                "close-navbar-menus": _vm.closeNavbarMenus
              },
              on: {
                toggleNavbarMenu: function($event) {
                  _vm.closeNavbarMenus = $event
                },
                showContacts: _vm.toggleContactsSidebar
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _c("router-view", {
                attrs: { "show-district": _vm.showDistrict },
                on: { showDistrict: _vm.toggleDistrictSidebar }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sidebar",
            {
              key: "sidebar-contacts",
              attrs: { open: _vm.showContacts },
              on: {
                closeSidebar: function($event) {
                  _vm.showContacts = false
                }
              }
            },
            [_c("contacts", { attrs: { open: _vm.showContacts } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("site-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }