<template>
  <div class="district-p-l">
    <div class="drawer-nav__header">
      <div class="d-flex align-items-center">
        <img src="/images/svg/mail.svg" alt="" aria-hidden="true" />
        <h3>{{ $t('common.contactsLabel') }}</h3>
      </div>
    </div>
    <div class="drawer-nav__type">
      <h4>{{ $t('contactsPage.factories') }}</h4>
    </div>
    <div class="drawer-nav__text">
      <div v-for="factory in factories" :key="factory.title" class="mb-3">
        <strong>{{ factory.title }}</strong>
        <p>{{ factory.address }}</p>
        <p>{{ factory.location }}</p>
        <p>
          {{ $t('contactsPage.coordinates') }}:
          <i class="fas fa-map-marker-alt mr-1 text-green" />
          <a
            :href="factory.coordinates.url"
            rel="noopener noreferrer"
            target="_blank"
            class="text-green"
          >
            {{ factory.coordinates.longLat }}
          </a>
        </p>
        <p v-if="factory.woodAcceptanceTime">
          {{ $t('contactsPage.woodAcceptanceTime') }}: I - V {{ factory.woodAcceptanceTime }}
        </p>
      </div>
    </div>
    <div class="drawer-nav__type">
      <h4>{{ $t('contactsPage.contactPersons') }}</h4>
    </div>

    <contact-persons />

    <div class="drawer-nav__type">
      <h4>{{ $t('contactsPage.props') }}</h4>
    </div>
    <div class="drawer-nav__text">
      <div class="mb-3">
        <p>
          <strong>{{ $t('contactsPage.disclaimer') }}</strong>
        </p>
      </div>
      <div class="mb-3">
        <strong>{{ $t('contactsPage.address') }}:</strong>
        <p>J. Basanavičiaus a. 9,</p>
        <p>LT-68308 Marijampolė,</p>
        <p>Lietuva</p>
      </div>
      <div class="mb-3">
        <p>
          {{ $t('contactsPage.emailShort') }}:
          <a href="mailto:info@miskovezis.lt">
            <span>info@miskovezis.lt</span>
          </a>
        </p>
        <p>
          {{ $t('contactsPage.website') }}:
          <a href="https://www.miskovezis.lt">
            <span>www.miskovezis.lt</span>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ContactPersons from '../../common/ContactPersons.vue';

export default {
  name: 'Contacts',
  components: { ContactPersons },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      factories: [
        {
          title: 'Akmenynų padalinys',
          address: 'Akmenynų km., LT-69300,',
          location: 'Kalvarijos sav., Lietuva',
          coordinates: {
            longLat: '54.409841,23.093817',
            url: 'http://maps.google.com/maps/ms?ie=UTF&msa=0&msid=202175584425752091004.0004afb57c09a69a75c5e&z=15&hl=en',
          },
        },
        {
          title: 'Jūrės padalinys',
          address: 'Medelyno g. 4B, LT–69446 Jūrės km.,',
          location: 'Kazlų Rūdos sav., Lietuva',
          coordinates: {
            longLat: '54.767919,23.485873',
            url: 'http://maps.google.com/maps/ms?ie=UTF&msa=0&msid=202175584425752091004.0004afb4b263a8992478b&z=15&hl=en',
          },
          woodAcceptanceTime: '8:00-17:00',
        },
        {
          title: 'Radviliškio padalinys',
          address: 'Dariaus ir S. Girėno g. 119, LT-82141, Radviliškis,',
          location: 'Radviliškio r. sav., Lietuva',
          coordinates: {
            longLat: '55.820092,23.519006',
            url: 'https://mapsengine.google.com/map/viewer?mid=zLULJGrDD0Tw.k_JKW87O9qds&z=15&hl=en',
          },
          woodAcceptanceTime: '6:00-19:00',
        },
        {
          title: 'Marijampolės padalinys',
          address: 'Juodelių g. 8, LT-69490 Nendriniškių km.,',
          location: 'Marijampolės sen., Marijampolės sav., Lietuva',
          coordinates: {
            longLat: '54.586296,23.412613',
            url: 'https://goo.gl/maps/LonCHWFo25amZC4H9',
          },
          woodAcceptanceTime: '6:00-21:00',
        },
      ],
    };
  },
};
</script>
