<template>
  <div class="drawer-nav__contact">
    <div v-for="worker in workers[getCurrentLanguage()]" :key="worker.name" class="d-flex mb-2">
      <div class="avatar">
        <img
          v-if="worker.imageUrl"
          :src="worker.imageUrl"
          :alt="`${worker.name} portretinė nuotrauka`"
        />
      </div>
      <div class="d-flex flex-column ml-3">
        <div class="worker">{{ worker.name }}</div>
        <div>
          <a :href="`tel:${worker.phone.replace(' ', '')}`">
            {{ worker.phone }}
          </a>
        </div>
        <div>
          <a :href="`mailto:${worker.email}`">
            {{ worker.email }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentLanguage } from '../../../utils';
import { config } from '../../config';

export default {
  name: 'ContactPersons',
  computed: {
    workers() {
      return {
        lt: [config.markets.lt.contactPerson, config.markets.lv.contactPerson],
        lv: [config.markets.lv.contactPerson, config.markets.lt.contactPerson],
      };
    },
  },
  methods: { getCurrentLanguage },
};
</script>
