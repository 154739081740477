import Es6Promise from 'es6-promise';
import 'babel-polyfill';
import 'url-search-params-polyfill';
import VueI18n from 'vue-i18n';
import '../sass/style.scss';
import axiosApi from 'axios';
import { directive as onClickaway } from 'vue-clickaway';
import Vue from 'vue';
import { router } from './app/router';
import App from './app/components/App.vue';
import lt from '../locales/app/lt.json';
import lv from '../locales/app/lv.json';
import { getCurrentLanguage } from './utils';

Es6Promise.polyfill();

const axios = axiosApi.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

window.axios = axios;

Vue.directive('on-clickaway', onClickaway);
Vue.directive('to-lowercase', {
  update(el) {
    el.value = el.value.toLowerCase();
  },
});

Vue.use(VueI18n);
Vue.filter('humanDate', (val) => {
  const date = new Date(val);
  const localTime = date.getTime();
  const localOffset = date.getTimezoneOffset() * 60000;
  const newDateTime = localTime - localOffset;
  const convertedDateTime = new Date(newDateTime);
  return new Intl.DateTimeFormat('lt-LT').format(convertedDateTime);
});

const store = require('./app/store').default;

const messages = { lt, lv };

const locale = getCurrentLanguage();

const i18n = new VueI18n({
  locale,
  messages,
});

new Vue({
  el: '#app',
  router,
  i18n,
  store,
  components: { App },
  template: '<App/>',
});
