<template>
  <div class="d-flex flex-column mh-100 lit-map map-bg">
    <div class="bg-white">
      <div class="container container-nav">
        <navbar
          :active-sidebar="getActiveSidebar"
          :close-navbar-menus="closeNavbarMenus"
          @toggleNavbarMenu="closeNavbarMenus = $event"
          @showContacts="toggleContactsSidebar"
        />
      </div>
    </div>
    <div class="container">
      <transition name="fade" mode="out-in">
        <router-view :show-district="showDistrict" @showDistrict="toggleDistrictSidebar" />
      </transition>
      <sidebar key="sidebar-contacts" :open="showContacts" @closeSidebar="showContacts = false">
        <contacts :open="showContacts" />
      </sidebar>
    </div>
    <site-footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navbar from './layout/Navbar.vue';
import SiteFooter from './layout/SiteFooter.vue';
import Sidebar from './layout/sidebars/Sidebar.vue';
import Contacts from './layout/sidebars/Contacts.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    SiteFooter,
    Sidebar,
    Contacts,
  },
  data() {
    return {
      closeNavbarMenus: false,
      showDistrict: false,
      showContacts: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
      signupFlow: 'signupFlow',
    }),
    getActiveSidebar() {
      if (this.showContacts) {
        return 'contacts';
      }
      return null;
    },
  },
  watch: {
    $route() {
      this.closeNavbarMenus = true;
    },
    user(newValue) {
      if (newValue && this.signupFlow.step !== 2) {
        this.checkIfCarrierInfoFilled().then(this.checkCarrierAgreements);
      }
    },
  },
  created() {
    axios.defaults.baseURL = apiUrl;
    axios.defaults.withCredentials = true;

    this.getTransportClients();

    document.title = this.$t('common.title');
  },
  mounted() {
    if (
      localStorage.getItem('wua') &&
      typeof JSON.parse(localStorage.getItem('wua')) === 'number'
    ) {
      this.checkIfLoggedIn();
    }
  },
  methods: {
    getTransportClients() {
      axios.get('/public/transport-client').then((resp) => {
        this.$store.dispatch('setTransportClients', resp.data.transportClients);
      });
    },
    checkIfLoggedIn() {
      this.$store.dispatch('setUserLoadStatus', 1);
      axios
        .get('/me')
        .then((resp) => {
          this.$store.dispatch('setUser', resp.data.user);
          this.$store.dispatch('setUserLoadStatus', 2);
          this.$store.dispatch(
            'setIsAdmin',
            resp.data.user.authorities && !!resp.data.user.authorities.length,
          );
        })
        .catch(() => {
          localStorage.removeItem('wua');
          this.$store.dispatch('setUser', null);
          this.$store.dispatch('setUserLoadStatus', 3);
          this.$store.dispatch('setIsAdmin', false);
        });
    },
    checkIfCarrierInfoFilled() {
      const {
        meta: { availableWithoutProfile },
      } = this.$route;

      return new Promise((resolve, reject) => {
        axios.get('/me/carrier').then((resp) => {
          if (resp.data.carrier === null) {
            if (!availableWithoutProfile) {
              this.$router.push({ name: 'signup' });
            }
            this.$store.dispatch('setSignupFlowUser', this.user);
            this.$store.dispatch('setSignupFlowStep', 2);
            reject();
          }
          resolve();
        });
      });
    },
    checkCarrierAgreements() {
      const {
        meta: { availableWithoutProfile },
      } = this.$route;

      return new Promise((resolve) => {
        axios.get('/me/carriage-agreement').then((resp) => {
          const agreements = resp.data.carriageAgreements;
          this.$store.dispatch('setCarriageAgreements', agreements);

          if (this.$route.name === 'signup' && agreements.length) {
            this.$router.push({ name: 'home' });
          }

          if (!agreements.length) {
            if (!availableWithoutProfile) {
              this.$router.push({ name: 'signup' });
            }

            this.$store.dispatch('setSignupFlowUser', this.user);
            this.$store.dispatch('setSignupFlowStep', 3);
          }

          resolve();
        });
      });
    },
    toggleDistrictSidebar() {
      this.showContacts = false;
      setTimeout(() => {
        this.showDistrict = true;
      }, 50);
    },
    toggleContactsSidebar() {
      this.showDistrict = false;
      setTimeout(() => {
        this.showContacts = true;
      }, 50);
    },
  },
};
</script>
