import Vuex from 'vuex';
import Vue from 'vue';
import { calculateCountdown } from '../../utils';

Vue.use(Vuex);

function convertToLoadingStatusesMap(auctionIds, status) {
  return auctionIds.reduce((agg, auctionId) => ({ ...agg, [auctionId]: status }), {});
}

function convertToNotificationMap(auctionIds, notification) {
  return auctionIds.reduce((agg, auctionId) => ({ ...agg, [auctionId]: notification }), {});
}

export default new Vuex.Store({
  state: {
    user: null,
    userLoadStatus: 0, // 0 - initial, 1 - loading, 2 - done, 3 - failed
    isAdmin: false,
    reservationProcessStatus: null,
    signupFlow: {
      user: null,
      step: 1,
    },
    transportClients: [],
    carriageAgreements: [],
    selectedTransportClientId: null,
    auctionsById: null,
    auctionStatusById: null, // 0 - initial, 1 - loading, 2 - done, 3 - failed
    auctionsCountdownById: null,
    auctionNotificationById: null,
    districtAuctionIds: null,
    hoveredDistrictCode: null,
    selectedDistrictCode: null,
    selectedAuctionId: null,
  },

  getters: {
    user: (state) => state.user,
    userLoadStatus: (state) => state.userLoadStatus,
    isAdmin: (state) => state.isAdmin,
    reservationProcessStatus: (state) => state.reservationProcessStatus,
    signupFlow: (state) => state.signupFlow,
    transportClients: (state) => state.transportClients,
    carriageAgreements: (state) => state.carriageAgreements,
    selectedTransportClientId: (state) => state.selectedTransportClientId,
    auctionsById: (state) => state.auctionsById,
    auctionStatusById: (state) => state.auctionStatusById,
    auctionNotificationById: (state) => state.auctionNotificationById,
    auctionsCountdownById: (state) => state.auctionsCountdownById,
    districtAuctionIds: (state) => state.districtAuctionIds,
    hoveredDistrictCode: (state) => state.hoveredDistrictCode,
    selectedDistrictCode: (state) => state.selectedDistrictCode,
    selectedAuctionId: (state) => state.selectedAuctionId,
  },

  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    setUserLoadStatus({ commit }, userLoadStatus) {
      commit('SET_USER_LOAD_STATUS', userLoadStatus);
    },
    setIsAdmin({ commit }, isAdmin) {
      commit('SET_IS_ADMIN', isAdmin);
    },
    setReservationProcessStatus({ commit }, status) {
      commit('SET_RESERVATION_PROCESS_STATUS', status);
    },
    setSignupFlowUser({ commit }, user) {
      commit('SET_SIGNUP_FLOW_USER', user);
    },
    setSignupFlowStep({ commit }, step) {
      commit('SET_SIGNUP_FLOW_STEP', step);
    },
    setTransportClients({ commit }, transportClients) {
      commit('SET_TRANSPORT_CLIENTS', transportClients);
    },
    setCarriageAgreements({ commit }, carriageAgreements) {
      commit('SET_CARRIAGE_AGREEMENTS', carriageAgreements);
    },
    setSelectedTransportClientId({ commit }, selectedTransportClientId) {
      commit('SET_SELECTED_TRANSPORT_CLIENT_ID', selectedTransportClientId);
    },
    setAuctionsById({ commit, dispatch }, auctions) {
      const mappedAuctions = auctions.reduce((agg, auction) => {
        agg[auction.id] = auction;

        return agg;
      }, {});
      commit('SET_AUCTIONS_BY_ID', mappedAuctions);
      commit('SET_AUCTION_STATUS_BY_IDS', {
        auctionIds: Object.keys(mappedAuctions),
        status: 2,
      });
      commit('SET_AUCTION_NOTIFICATION_BY_ID', {
        auctionIds: Object.keys(mappedAuctions),
        notification: null,
      });
      dispatch('setDistrictAuctionIds');
    },
    setAuction({ commit, state, dispatch }, { auction, notification }) {
      commit('SET_AUCTIONS_BY_ID', {
        ...state.auctionsById,
        [auction.id]: auction,
      });
      commit('SET_AUCTION_STATUS_BY_IDS', {
        auctionIds: [auction.id],
        status: 2,
      });
      commit('SET_AUCTION_NOTIFICATION_BY_ID', {
        auctionIds: [auction.id],
        notification: notification || null,
      });
      dispatch('setDistrictAuctionIds');
    },
    removeAuction({ commit, dispatch }, auctionId) {
      commit('REMOVE_AUCTION', auctionId);
      commit('REMOVE_AUCTION_STATUS', auctionId);
      commit('REMOVE_AUCTION_COUNTDOWN', auctionId);
      dispatch('setDistrictAuctionIds');
    },
    setAuctionCountdownByIds({ commit, state }) {
      const auctionIds = Object.keys(state.auctionsById);

      let i = 0;
      while (i < auctionIds.length) {
        const auctionId = auctionIds[i];
        const auction = state.auctionsById[auctionId];
        const { time, countdownProgress } = calculateCountdown(auction);

        if (time.hours < 1 && time.minutes < 1 && time.seconds < 1) {
          commit('SET_AUCTION_STATUS_BY_IDS', {
            auctionIds: [auctionId],
            status: 1,
          });
        }

        commit('SET_AUCTION_COUNTDOWN_BY_ID', {
          auctionId,
          countdown: { time, countdownProgress },
        });
        i += 1;
      }
    },
    setDistrictAuctionIds({ commit, state }) {
      const auctions = Object.values(state.auctionsById);
      const districtCodes = auctions
        .map((a) => a.district.code)
        .filter((v, i, a) => a.indexOf(v) === i);
      const mappedDistricts = districtCodes.reduce((agg, districtCode) => {
        const districtAuctions = auctions.filter((u) => u.district.code === districtCode);
        agg[districtCode] = districtAuctions.map((d) => d.id);

        return agg;
      }, {});

      commit('SET_DISTRICT_AUCTION_IDS', mappedDistricts);
    },
    setSelectedDistrictCode({ commit }, selectedDistrictCode) {
      commit('SET_SELECTED_DISTRICT_CODE', selectedDistrictCode);
    },
    setHoveredDistrictCode({ commit }, hoveredDistrictCode) {
      commit('SET_HOVERED_DISTRICT_CODE', hoveredDistrictCode);
    },
    setSelectedAuctionId({ commit }, auctionId) {
      commit('SET_SELECTED_AUCTION_ID', auctionId);
    },
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USER_LOAD_STATUS(state, userLoadStatus) {
      state.userLoadStatus = userLoadStatus;
    },
    SET_IS_ADMIN(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    SET_RESERVATION_PROCESS_STATUS(state, status) {
      state.reservationProcessStatus = status;
    },
    SET_SIGNUP_FLOW_USER(state, user) {
      state.signupFlow.user = user;
    },
    SET_SIGNUP_FLOW_STEP(state, step) {
      state.signupFlow.step = step;
    },
    SET_TRANSPORT_CLIENTS(state, transportClients) {
      state.transportClients = transportClients;
    },
    SET_CARRIAGE_AGREEMENTS(state, carriageAgreements) {
      state.carriageAgreements = carriageAgreements;
    },
    SET_SELECTED_TRANSPORT_CLIENT_ID(state, selectedTransportClientId) {
      state.selectedTransportClientId = selectedTransportClientId;
    },
    SET_AUCTIONS_BY_ID(state, auctionsById) {
      state.auctionsById = auctionsById;
    },
    SET_AUCTION_NOTIFICATION_BY_ID(state, { auctionIds, notification }) {
      state.auctionNotificationById = {
        ...state.auctionNotificationById,
        ...convertToNotificationMap(auctionIds, notification),
      };
    },
    SET_AUCTION_STATUS_BY_IDS(state, { auctionIds, status }) {
      state.auctionStatusById = {
        ...state.auctionStatusById,
        ...convertToLoadingStatusesMap(auctionIds, status),
      };
    },
    SET_AUCTION_COUNTDOWN_BY_ID(state, { auctionId, countdown }) {
      state.auctionsCountdownById = {
        ...state.auctionsCountdownById,
        [auctionId]: countdown,
      };
    },
    REMOVE_AUCTION(state, auctionId) {
      delete state.auctionsById[auctionId];
    },
    REMOVE_AUCTION_STATUS(state, auctionId) {
      delete state.auctionStatusById[auctionId];
    },
    REMOVE_AUCTION_COUNTDOWN(state, auctionId) {
      delete state.auctionsCountdownById[auctionId];
    },
    REMOVE_AUCTION_NOTIFICATION(state, auctionId) {
      delete state.auctionNotificationById[auctionId];
    },
    SET_DISTRICT_AUCTION_IDS(state, districts) {
      state.districtAuctionIds = districts;
    },
    SET_HOVERED_DISTRICT_CODE(state, hoveredDistrictCode) {
      state.hoveredDistrictCode = hoveredDistrictCode;
    },
    SET_SELECTED_DISTRICT_CODE(state, selectedDistrictCode) {
      state.selectedDistrictCode = selectedDistrictCode;
    },
    SET_SELECTED_AUCTION_ID(state, selectedAuctionId) {
      state.selectedAuctionId = selectedAuctionId;
    },
  },
});
