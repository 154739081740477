import Vue from 'vue';
import Router from 'vue-router';
import { extractLocaleFromPathname } from '../../utils';

const AuctionMap = () =>
  import(/* webpackChunkName: "AuctionMap" */ '../components/layout/map/AuctionMap.vue');
const ErrorPage = () =>
  import(/* webpackChunkName: "ErrorPage" */ '../components/common/ErrorPage.vue');
const Login = () => import(/* webpackChunkName: "Login" */ '../components/Login.vue');
const Signup = () => import(/* webpackChunkName: "Signup" */ '../components/signup/Index.vue');
const PasswordReset = () =>
  import(/* webpackChunkName: "PasswordReset" */ '../components/PasswordReset.vue');
const NewPasswordSubmission = () =>
  import(/* webpackChunkName: "NewPasswordSubmission" */ '../components/NewPasswordSubmission.vue');
const Reservations = () =>
  import(/* webpackChunkName: "Reservations" */ '../components/Reservations.vue');
const Profile = () => import(/* webpackChunkName: "Profile" */ '../components/profile/Index.vue');
const PrivacyPolicy = () =>
  import(/* webpackChunkName: "PrivacyPolicy" */ '../components/PrivacyPolicy.vue');
const CookiePolicy = () =>
  import(/* webpackChunkName: "CookiePolicy" */ '../components/CookiePolicy.vue');

Vue.use(Router);

const ifAuthenticated = (to, from, next) => {
  if (typeof JSON.parse(localStorage.getItem('wua')) === 'number') {
    next();
    return;
  }
  next('/');
};
const ifNotAuthenticated = (to, from, next) => {
  if (!localStorage.getItem('wua')) {
    next();
    return;
  }
  next('/');
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: AuctionMap,
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
    props: true,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/join',
    name: 'signup',
    component: Signup,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    props: true,
    component: PasswordReset,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/password-reset/:userId/:token',
    name: 'new-password-submission',
    component: NewPasswordSubmission,
    props: true,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/reservations',
    name: 'reservations',
    component: Reservations,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    meta: {
      availableWithoutProfile: true,
    },
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy',
    component: CookiePolicy,
    meta: {
      availableWithoutProfile: true,
    },
  },
  {
    path: '*',
    component: ErrorPage,
    props: true,
    meta: {
      errorPage: 404,
      availableWithoutProfile: true,
    },
  },
];

const locale = extractLocaleFromPathname();

export const router = new Router({
  routes,
  base: locale,
  mode: 'history',
});
