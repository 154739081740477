<template>
  <aside
    :id="customId"
    v-on-clickaway="close"
    class="drawer-nav"
    :class="[{ 'is-open': isOpen }, customClass]"
  >
    <div class="drawer-nav__close text-right">
      <i class="fas fa-times" @click="close" />
    </div>
    <div class="drawer-nav__wrapper">
      <div class="w-100 flex-1">
        <slot />
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    customClass: {
      type: String,
      required: false,
      default: null,
    },
    customId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    open: {
      handler(val) {
        this.isOpen = val;
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.isOpen = false;
      this.$emit('closeSidebar');
    },
  },
};
</script>
