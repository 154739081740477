var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "bg-white" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "position-relative py-3" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [
            _c("div", [
              _vm._v(
                "© " +
                  _vm._s(_vm.thisYear) +
                  " " +
                  _vm._s(_vm.$t("footer.copyright"))
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "position-relative" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "pl-sm-2 d-sm-inline d-block",
                    attrs: { to: { name: "privacy-policy" }, tag: "a" }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("privacyPolicyPage.title")) +
                        "\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "pl-sm-2 d-sm-inline d-block",
                    attrs: { to: { name: "cookie-policy" }, tag: "a" }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("cookiePolicyPage.title")) +
                        "\n          "
                    )
                  ]
                )
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "floating-btn__wrapper" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary floating-btn",
              attrs: { type: "button" },
              on: { click: _vm.openFreshChat }
            },
            [
              _c("span", { staticClass: "d-inline-flex align-items-center" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("footer.consultationCTA")) +
                    "\n          "
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }